::-webkit-scrollbar {
width: 8px;
height: 8px;
}

::-webkit-scrollbar-track {
background: #f1f1f1;
box-shadow: inset 0 0 5px rgb(225, 225, 225);
}

::-webkit-scrollbar-thumb {
background: #999;
border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
background: #777;
}